import { useDiscountsAppEmbedStatusQuery } from "graphql/queries/discounts-app-embed-status.generated";
import { useMainThemeHasEmbeddedInstallQuery } from "graphql/queries/main-theme-has-embedded-install.generated";

export default function useDiscountsAppEmbedNeedsEnabling() {
  const {
    data: embedStatusQueryData,
    error: embedStatusQueryError,
    loading: embedStatusQueryLoading
  } = useDiscountsAppEmbedStatusQuery();

  const {
    data: mainThemeQueryData,
    error: mainThemeQueryError,
    loading: mainThemeQueryLoading
  } = useMainThemeHasEmbeddedInstallQuery();

  if (embedStatusQueryLoading || mainThemeQueryLoading) {
    return { loading: true };
  }

  function isLegacyFlow() {
    const isAppEmbedEnabled =
      embedStatusQueryData?.discountsAppEmbedStatus.enabled || false;
    const isEmbeddedInstall =
      mainThemeQueryData?.mainTheme.hasEmbeddedInstall || false;
    if (isAppEmbedEnabled) {
      return false;
    } else if (isEmbeddedInstall) {
      return true;
    } else {
      return false;
    }
  }

  if (
    embedStatusQueryError ||
    mainThemeQueryError ||
    mainThemeQueryData?.mainTheme.hasEmbeddedInstall
  ) {
    return {
      needsEnabling: false,
      legacyFlow: isLegacyFlow()
    };
  }

  return {
    needsEnabling: !embedStatusQueryData?.discountsAppEmbedStatus.enabled,
    legacyFlow: isLegacyFlow()
  };
}
